import React,{useEffect,useState} from 'react'
import Container from 'react-bootstrap/Container';
import {Nav,Navbar,Button,Card} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import CommonButton from '../Desktop/SubComponents/CommonButton';
import { navbarmbAPI } from '../../utils/NavbarMbAPI';
import Accordion from 'react-bootstrap/Accordion';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
const MyNavMb = () => {
  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = useState(null);
  const [phemail,setphemail] = useState("");
  const [number,setnumber] = useState("")
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];

  const handleSelectoption=(e)=>{
    // setSelectedOption(e.)
    setSelectedOption(e.value)
  }
  const handleLogin = ()=>{
    navigate('/login') 
  }
  return (
    <>
        <Navbar className='mynavbarmb' id="mynavbar2" expand="lg">

        <div className="mycontainermb flexwrapnav ">

        <Link to="/"><img src="/assets/images/Common/logo/mainlogo.png" alt="" /></Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

          </div>
          <div className="mycontainermb2 navtoggleshadow w-100">
        <Navbar.Collapse id="basic-navbar-nav">
            {/* <Nav className="m-auto">
            <Link className="nav-link" to="/courses">Courses</Link>
            <Link className="nav-link" to="/aboutus">About Us</Link>
            <Link className="nav-link" to="/notice">Notice</Link>
            <Link className="nav-link" to="/blog">Blog</Link>
            <Link className="nav-link" to="/donation">Donation</Link>
            <Link className="nav-link" to="/contact">Contact Us</Link>
            </Nav> */}
           
            
              {

                navbarmbAPI.map((item,key)=>{
                  if(item.dropdwn==true){

                    return (
                  <Accordion defaultActiveKey="0" key={key}>
                                  <Accordion.Item eventKey={item.id} style={{border:"0"}}>
                                  <Accordion.Header><Link className="nav-link" to="/courses">{item.heading}</Link></Accordion.Header>
                                  
                       { item.dropdowndata.map((item2,key2)=>{
                        if(item2.length!==0){
                          return(
                                        <Accordion.Body className='acc-body' key={item2.id}>
                                        <img className='w-100' src={item2.img} alt="" />
                                        <h3>{item2.head}</h3>
                                        <p>{item2.para}</p>
                                        </Accordion.Body>                     
                                 
                                 )
                                 
                                }
                                else{
                                  return(
                                    <Card key={item2.id}>{item.heading}</Card>
                          )
                        }
                      })
                    }
                      </Accordion.Item>
                    </Accordion>
  
  
                    )
                  }
                  else if(item.donationdrpdwn==true){
                    return (
                      <Accordion defaultActiveKey="0" key={key}>
                                      <Accordion.Item eventKey={item.id} style={{border:"0"}}>
                                      <Accordion.Header><Link className="nav-link" to="/courses">{item.heading}</Link></Accordion.Header>
                                      
                           { item.dropdowndata.map((item2,key2)=>{
                            if(item2.length!==0){
                              return(
                                            <Accordion.Body className='acc-body' key={item2.id}>
                                              <h3>{item2.heading}</h3>
                                              <Select
                                                  defaultValue={selectedOption}
                                                  onChange={handleSelectoption}
                                                  options={options}
                                              />
                                              <div className="basicinput">
                                                  <h3></h3>
                                                  <input type="text" placeholder='Phone/Email' name="phemail" value={phemail} onChange={(e)=>setphemail(e.target.value)}/>
                                              </div>
                                              <div className="basicinput">
                                                  <h3></h3>
                                                  <input type="number" placeholder='Type An Amount' name="number" value={number} onChange={(e)=>setnumber(e.target.value)} />
                                              </div>
                                            </Accordion.Body>                     
                                     
                                     )
                                     
                                    }
                                    else{
                                      return(
                                        <Card key={item2.id}>{item.heading}</Card>
                              )
                            }
                          })
                        }
                          </Accordion.Item>
                        </Accordion>
      
      
                        )
                  }
                  else{
                    return(
                      
                        <Card key={key} eventKey={item.id} style={{border:"0"}}>
                        <Link className="nav-link" style={{height:"53px",paddingLeft:"20px",lineHeight:"53px"}} to={item.link}>{item.heading}</Link>
                        
                      </Card>
                    
                    )
                  }
                })
                  
                
                      
                         
              }
            
                    
               
              
           
           
            
            
            <div className="buttonsdiv d-flex justify-content-center">
                <Button onClick={handleLogin} className='loginbutton'>Login</Button>
                <CommonButton text="Sign Up"/>
            </div>
        </Navbar.Collapse>

          </div>
        
        
        
        </Navbar>
    </>
  )
}

export default MyNavMb