import React from 'react'


const navbarmbAPI = [
    {
        id:1,
        heading:"Courses",
        donationdrpdwn:false,
        dropdwn:true,
        link:"/courses",
        dropdowndata:[
            {
                heading:"Courses",                
                id:1,
                img:"/assets/images/NavbarMb/Dropdownitems/1.png",
                head:"Course Title 1",
                para:"Lorem ipsum dolor sit amet consectetur. Neque mauris lorem ipsum dolor sit amet consectetur  nmauris"
            },
            {
                heading:"Courses",                
                id:2,
                img:"/assets/images/NavbarMb/Dropdownitems/2.png",
                head:"Course Title 2",
                para:"Lorem ipsum dolor sit amet consectetur. Neque mauris lorem ipsum dolor sit amet consectetur  nmauris"
            },
        ]
    },
    {
        id:2,
        dropdwn:false,
        donationdrpdwn:false,
        link:"/aboutus",
        heading:"About us",
        dropdowndata:[]
    },
    {
        id:3,
        dropdwn:false,
        donationdrpdwn:false,
        heading:"Notice",
        link:'/notice',
        dropdowndata:[]
    },
    {
        id:4,
        heading:"Blog",
        donationdrpdwn:false,
        dropdwn:true,
        link:"/blog",
        dropdowndata:[
            {
                heading:"Courses",                
                id:1,
                img:"/assets/images/NavbarMb/Dropdownitems/1.png",
                head:"Blog Title 1",
                para:"Lorem ipsum dolor sit amet consectetur. Neque mauris lorem ipsum dolor sit amet consectetur  nmauris"
            },
            {
                heading:"Courses",                
                id:2,
                img:"/assets/images/NavbarMb/Dropdownitems/2.png",
                head:"Blog Title 2",
                para:"Lorem ipsum dolor sit amet consectetur. Neque mauris lorem ipsum dolor sit amet consectetur  nmauris"
            },
        ]
    },
    {
    id:5,
    dropdwn:false,
    donationdrpdwn:true,
    heading:"Donation",
    link:'/dontation',
    dropdowndata:[
        {
            heading:"Care for a donation!",

        }
    ]
    },
    {
    id:6,
    dropdwn:false,
    donationdrpdwn:false,
    heading:"Contact Us",
    link:'/contact',
    dropdowndata:[]
    },

]

const NavbarMbAPI = () => {
  return (
    <>
    
    </>
  )
}

export {navbarmbAPI}

export default NavbarMbAPI