import React from 'react'

const courseCardAPI = [
    
        {
            id:1,
            pricebox:{
                price:1300,
                instructor:"Mokhter Ahmad",
                duration:"08 hr 15 mins"                ,
                lectures:"29",
                level:"02",
                language:"English",
                certificate:"Yes"
            },
            head:"Quranic Arabic Blended Professional 1",
            lessons:5,rating:4.7,
            days:15,
            level:"Level (2)",
            imgurl:"/assets/images/Homepage/mostpopularcourses/instructorlittlepic.png",
            instructorname:"Professor Mokhter Ahmad",
            price:1200,
            bigimgurl:"/assets/images/Homepage/mostpopularcourses/cardpic1.png",
            description:{
                title:"Description",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread."
            },
            curriculum:{
                title:"Curriculum",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran."
            },
            certification:{
                title:"Certification",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran. Every Muslim recognizes the importance of learning"
            },
            coursecontent:[

                {
                    heading:"Lesson 1 : Preview",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab2"
                        },
                        {
                            name:"Subtab3"
                        },
                        {
                            name:"Subtab4"
                        },
                    ]
                },
                {
                    heading:"Lesson 2 : Step 2",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                    ]
                },
            ],
            instructors:{
                heading:"Course Instructor:",
                rating:4.7,
                ratingcount:30,
                teachername:"Professor Mokhter Ahmad",
                designation:"Tarbiyah Ins, Instructor",
                ratingsection:{
                    heading:"Rating",
                    rating:4.7,
                    totalratednumber:4200,
                    fivestarpercent:60,
                    fourstarpercent:30,
                    threestarpercent:15,
                    twostarpercent:10,
                    onestarpercent:5,
                }
            },
            studentreview:{
                heading:"Student Review:",
                data:[
                    {
                        name:"Student Name",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 2",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 3",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 4",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                ]
            }


        },
        {
            id:2,
            pricebox:{
                price:1300,
                instructor:"Mokhter Ahmad",
                duration:"08 hr 15 mins"                ,
                lectures:"29",
                level:"02",
                language:"English",
                certificate:"Yes"
            },
            head:"Quranic Arabic Grammar",
            lessons:5,rating:4.7,
            days:15,
            level:"All Levels",
            imgurl:"/assets/images/Homepage/mostpopularcourses/instructorlittlepic.png",
            instructorname:"Professor Mokhter Ahmad",
            price:1200,
            bigimgurl:"/assets/images/Homepage/mostpopularcourses/cardpic2.png",
            saletag:true,
            description:{
                title:"Description",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread."
            },
            curriculum:{
                title:"Curriculum",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran."
            },
            certification:{
                title:"Certification",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran. Every Muslim recognizes the importance of learning"
            },
            coursecontent:[

                {
                    heading:"Lesson 1 : Preview",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                    ]
                }
            ],
            instructors:{
                heading:"Course Instructor:",
                rating:4.7,
                ratingcount:30,
                teachername:"Professor Mokhter Ahmad",
                designation:"Tarbiyah Ins, Instructor",
                 ratingsection:{
                    heading:"Rating",
                    rating:4.7,
                    totalratednumber:4200,
                    fivestarpercent:60,
                    fourstarpercent:30,
                    threestarpercent:15,
                    twostarpercent:10,
                    onestarpercent:5,
                }
            },
            studentreview:{
                heading:"Student Review:",
                data:[
                    {
                        name:"Student Name",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 2",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 3",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 4",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                ]
            }
        },
        {
            id:3,
            pricebox:{
                price:1300,
                instructor:"Mokhter Ahmad",
                duration:"08 hr 15 mins"                ,
                lectures:"29",
                level:"02",
                language:"English",
                certificate:"Yes"
            },
            head:"Quranic arabic blended",
            lessons:5,rating:4.7,
            days:15,
            level:"Level (3)",
            imgurl:"/assets/images/Homepage/mostpopularcourses/instructorlittlepic.png",
            instructorname:"Professor Mokhter Ahmad",
            price:1200,
            bigimgurl:"/assets/images/Homepage/mostpopularcourses/cardpic3.png",
            description:{
                title:"Description",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread."
            },
            curriculum:{
                title:"Curriculum",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran."
            },
            certification:{
                title:"Certification",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran. Every Muslim recognizes the importance of learning"
            },
            coursecontent:[

                {
                    heading:"Lesson 1 : Preview",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                    ]
                }
            ],
            instructors:{
                heading:"Course Instructor:",
                rating:4.7,
                ratingcount:30,
                teachername:"Professor Mokhter Ahmad",
                designation:"Tarbiyah Ins, Instructor",
                 ratingsection:{
                    heading:"Rating",
                    rating:4.7,
                    totalratednumber:4200,
                    fivestarpercent:60,
                    fourstarpercent:30,
                    threestarpercent:15,
                    twostarpercent:10,
                    onestarpercent:5,
                }
            },
            studentreview:{
                heading:"Student Review:",
                data:[
                    {
                        name:"Student Name",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 2",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 3",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 4",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                ]
            }
        },
        {
            id:4,
            pricebox:{
                price:1300,
                instructor:"Mokhter Ahmad",
                duration:"08 hr 15 mins"                ,
                lectures:"29",
                level:"02",
                language:"English",
                certificate:"Yes"
            },
            head:"Quranic Arabic Grammar, Morphology",
            lessons:5,rating:4.7,
            days:15,
            level:"All Levels",
            imgurl:"/assets/images/Homepage/mostpopularcourses/instructorlittlepic.png",
            instructorname:"Professor Mokhter Ahmad",
            price:1200,
            bigimgurl:"/assets/images/Homepage/mostpopularcourses/cardpic4.png",
            description:{
                title:"Description",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread."
            },
            curriculum:{
                title:"Curriculum",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran."
            },
            certification:{
                title:"Certification",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran. Every Muslim recognizes the importance of learning"
            },
            coursecontent:[

                {
                    heading:"Lesson 1 : Preview",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                    ]
                }
            ],
            instructors:{
                heading:"Course Instructor:",
                rating:4.7,
                ratingcount:30,
                teachername:"Professor Mokhter Ahmad",
                designation:"Tarbiyah Ins, Instructor",
                 ratingsection:{
                    heading:"Rating",
                    rating:4.7,
                    totalratednumber:4200,
                    fivestarpercent:60,
                    fourstarpercent:30,
                    threestarpercent:15,
                    twostarpercent:10,
                    onestarpercent:5,
                }
            },
            studentreview:{
                heading:"Student Review:",
                data:[
                    {
                        name:"Student Name",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 2",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 3",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 4",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                ]
            }
        },
        {
            id:5,
            pricebox:{
                price:1500,
                instructor:"Mokhter Ahmad",
                duration:"08 hr 15 mins"                ,
                lectures:"29",
                level:"02",
                language:"English",
                certificate:"Yes"
            },
            head:"Balagah & Fasahah",
            lessons:5,rating:4.7,
            days:15,
            level:"Level (2)",
            imgurl:"/assets/images/Homepage/mostpopularcourses/instructorlittlepic.png",
            instructorname:"Professor Mokhter Ahmad",
            price:1200,
            bigimgurl:"/assets/images/Homepage/mostpopularcourses/cardpic5.png",
            description:{
                title:"Description",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread."
            },
            curriculum:{
                title:"Curriculum",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran."
            },
            certification:{
                title:"Certification",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran. Every Muslim recognizes the importance of learning"
            },
            coursecontent:[

                {
                    heading:"Lesson 1 : Preview",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                    ]
                }
            ],
            instructors:{
                heading:"Course Instructor:",
                rating:4.7,
                ratingcount:30,
                teachername:"Professor Mokhter Ahmad",
                designation:"Tarbiyah Ins, Instructor",
                 ratingsection:{
                    heading:"Rating",
                    rating:4.7,
                    totalratednumber:4200,
                    fivestarpercent:60,
                    fourstarpercent:30,
                    threestarpercent:15,
                    twostarpercent:10,
                    onestarpercent:5,
                }
            },
            studentreview:{
                heading:"Student Review:",
                data:[
                    {
                        name:"Student Name",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 2",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 3",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 4",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                ]
            }
        },
        {
            id:6,
            pricebox:{
                price:1300,
                instructor:"Mokhter Ahmad",
                duration:"08 hr 15 mins"                ,
                lectures:"29",
                level:"02",
                language:"English",
                certificate:"Yes"
            },
            head:"Quranic arabic blended proffessional (surah baqarah)",
            lessons:5,rating:4.7,
            days:15,
            level:"Level (2)",
            imgurl:"/assets/images/Homepage/mostpopularcourses/instructorlittlepic.png",
            instructorname:"Professor Mokhter Ahmad",
            price:1200,
            bigimgurl:"/assets/images/Homepage/mostpopularcourses/cardpic6.png",
            description:{
                title:"Description",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread."
            },
            curriculum:{
                title:"Curriculum",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran."
            },
            certification:{
                title:"Certification",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran. Every Muslim recognizes the importance of learning"
            },
            coursecontent:[

                {
                    heading:"Lesson 1 : Preview",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                    ]
                }
            ],
            instructors:{
                heading:"Course Instructor:",
                rating:4.7,
                ratingcount:30,
                teachername:"Professor Mokhter Ahmad",
                designation:"Tarbiyah Ins, Instructor",
                 ratingsection:{
                    heading:"Rating",
                    rating:4.7,
                    totalratednumber:4200,
                    fivestarpercent:60,
                    fourstarpercent:30,
                    threestarpercent:15,
                    twostarpercent:10,
                    onestarpercent:5,
                }
            },
            studentreview:{
                heading:"Student Review:",
                data:[
                    {
                        name:"Student Name",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 2",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 3",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 4",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                ]
            }
        },
    

    ]


    const popularCoursesCardAPI = [
        {
            id:1,
            pricebox:{
                price:1300,
                instructor:"Mokhter Ahmad",
                duration:"08 hr 15 mins"                ,
                lectures:"29",
                level:"02",
                language:"English",
                certificate:"Yes"
            },
            head:"Quranic Arabic Blended Professional 1",
            lessons:5,rating:4.7,
            days:15,
            level:"Level (2)",
            imgurl:"/assets/images/Homepage/mostpopularcourses/instructorlittlepic.png",
            instructorname:"Professor Mokhter Ahmad",
            price:1200,
            bigimgurl:"/assets/images/Homepage/mostpopularcourses/cardpic3.png",
            description:{
                title:"Description",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread."
            },
            curriculum:{
                title:"Curriculum",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran."
            },
            certification:{
                title:"Certification",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran. Every Muslim recognizes the importance of learning"
            },
            coursecontent:[

                {
                    heading:"Lesson 1 : Preview",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab2"
                        },
                        {
                            name:"Subtab3"
                        },
                        {
                            name:"Subtab4"
                        },
                    ]
                },
                {
                    heading:"Lesson 2 : Step 2",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                    ]
                },
            ],
            instructors:{
                heading:"Course Instructor:",
                rating:4.7,
                ratingcount:30,
                teachername:"Professor Mokhter Ahmad",
                designation:"Tarbiyah Ins, Instructor",
                ratingsection:{
                    heading:"Rating",
                    rating:4.7,
                    totalratednumber:4200,
                    fivestarpercent:60,
                    fourstarpercent:30,
                    threestarpercent:15,
                    twostarpercent:10,
                    onestarpercent:5,
                }
            },
            studentreview:{
                heading:"Student Review:",
                data:[
                    {
                        name:"Student Name",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 2",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 3",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 4",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                ]
            }


        },
        {
            id:2,
            pricebox:{
                price:1300,
                instructor:"Mokhter Ahmad",
                duration:"08 hr 15 mins"                ,
                lectures:"29",
                level:"02",
                language:"English",
                certificate:"Yes"
            },
            head:"Quranic Arabic Grammar",
            lessons:5,rating:4.7,
            days:15,
            level:"All Levels",
            imgurl:"/assets/images/Homepage/mostpopularcourses/instructorlittlepic.png",
            instructorname:"Professor Mokhter Ahmad",
            price:1200,
            bigimgurl:"/assets/images/Homepage/mostpopularcourses/cardpic2.png",
            saletag:true,
            description:{
                title:"Description",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread."
            },
            curriculum:{
                title:"Curriculum",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran."
            },
            certification:{
                title:"Certification",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran. Every Muslim recognizes the importance of learning"
            },
            coursecontent:[

                {
                    heading:"Lesson 1 : Preview",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                    ]
                }
            ],
            instructors:{
                heading:"Course Instructor:",
                rating:4.7,
                ratingcount:30,
                teachername:"Professor Mokhter Ahmad",
                designation:"Tarbiyah Ins, Instructor",
                 ratingsection:{
                    heading:"Rating",
                    rating:4.7,
                    totalratednumber:4200,
                    fivestarpercent:60,
                    fourstarpercent:30,
                    threestarpercent:15,
                    twostarpercent:10,
                    onestarpercent:5,
                }
            },
            studentreview:{
                heading:"Student Review:",
                data:[
                    {
                        name:"Student Name",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 2",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 3",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 4",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                ]
            }
        },
        {
            id:3,
            pricebox:{
                price:1300,
                instructor:"Mokhter Ahmad",
                duration:"08 hr 15 mins"                ,
                lectures:"29",
                level:"02",
                language:"English",
                certificate:"Yes"
            },
            head:"Quranic arabic blended",
            lessons:5,rating:4.7,
            days:15,
            level:"Level (3)",
            imgurl:"/assets/images/Homepage/mostpopularcourses/instructorlittlepic.png",
            instructorname:"Professor Mokhter Ahmad",
            price:1200,
            bigimgurl:"/assets/images/Homepage/mostpopularcourses/cardpic1.png",
            description:{
                title:"Description",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread."
            },
            curriculum:{
                title:"Curriculum",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran."
            },
            certification:{
                title:"Certification",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran. Every Muslim recognizes the importance of learning"
            },
            coursecontent:[

                {
                    heading:"Lesson 1 : Preview",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                    ]
                }
            ],
            instructors:{
                heading:"Course Instructor:",
                rating:4.7,
                ratingcount:30,
                teachername:"Professor Mokhter Ahmad",
                designation:"Tarbiyah Ins, Instructor",
                 ratingsection:{
                    heading:"Rating",
                    rating:4.7,
                    totalratednumber:4200,
                    fivestarpercent:60,
                    fourstarpercent:30,
                    threestarpercent:15,
                    twostarpercent:10,
                    onestarpercent:5,
                }
            },
            studentreview:{
                heading:"Student Review:",
                data:[
                    {
                        name:"Student Name",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 2",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 3",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 4",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                ]
            }
        },
        {
            id:4,
            pricebox:{
                price:1300,
                instructor:"Mokhter Ahmad",
                duration:"08 hr 15 mins"                ,
                lectures:"29",
                level:"02",
                language:"English",
                certificate:"Yes"
            },
            head:"Quranic Arabic Grammar, Morphology",
            lessons:5,rating:4.7,
            days:15,
            level:"All Levels",
            imgurl:"/assets/images/Homepage/mostpopularcourses/instructorlittlepic.png",
            instructorname:"Professor Mokhter Ahmad",
            price:1200,
            bigimgurl:"/assets/images/Homepage/mostpopularcourses/cardpic6.png",
            description:{
                title:"Description",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread."
            },
            curriculum:{
                title:"Curriculum",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran."
            },
            certification:{
                title:"Certification",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran. Every Muslim recognizes the importance of learning"
            },
            coursecontent:[

                {
                    heading:"Lesson 1 : Preview",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                    ]
                }
            ],
            instructors:{
                heading:"Course Instructor:",
                rating:4.7,
                ratingcount:30,
                teachername:"Professor Mokhter Ahmad",
                designation:"Tarbiyah Ins, Instructor",
                 ratingsection:{
                    heading:"Rating",
                    rating:4.7,
                    totalratednumber:4200,
                    fivestarpercent:60,
                    fourstarpercent:30,
                    threestarpercent:15,
                    twostarpercent:10,
                    onestarpercent:5,
                }
            },
            studentreview:{
                heading:"Student Review:",
                data:[
                    {
                        name:"Student Name",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 2",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 3",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 4",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                ]
            }
        },
        {
            id:5,
            pricebox:{
                price:1500,
                instructor:"Mokhter Ahmad",
                duration:"08 hr 15 mins"                ,
                lectures:"29",
                level:"02",
                language:"English",
                certificate:"Yes"
            },
            head:"Balagah & Fasahah",
            lessons:5,rating:4.7,
            days:15,
            level:"Level (2)",
            imgurl:"/assets/images/Homepage/mostpopularcourses/instructorlittlepic.png",
            instructorname:"Professor Mokhter Ahmad",
            price:1200,
            bigimgurl:"/assets/images/Homepage/mostpopularcourses/cardpic5.png",
            description:{
                title:"Description",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread."
            },
            curriculum:{
                title:"Curriculum",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran."
            },
            certification:{
                title:"Certification",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran. Every Muslim recognizes the importance of learning"
            },
            coursecontent:[

                {
                    heading:"Lesson 1 : Preview",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                    ]
                }
            ],
            instructors:{
                heading:"Course Instructor:",
                rating:4.7,
                ratingcount:30,
                teachername:"Professor Mokhter Ahmad",
                designation:"Tarbiyah Ins, Instructor",
                 ratingsection:{
                    heading:"Rating",
                    rating:4.7,
                    totalratednumber:4200,
                    fivestarpercent:60,
                    fourstarpercent:30,
                    threestarpercent:15,
                    twostarpercent:10,
                    onestarpercent:5,
                }
            },
            studentreview:{
                heading:"Student Review:",
                data:[
                    {
                        name:"Student Name",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 2",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 3",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 4",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                ]
            }
        },
        {
            id:6,
            pricebox:{
                price:1300,
                instructor:"Mokhter Ahmad",
                duration:"08 hr 15 mins"                ,
                lectures:"29",
                level:"02",
                language:"English",
                certificate:"Yes"
            },
            head:"Quranic arabic blended proffessional (surah baqarah)",
            lessons:5,rating:4.7,
            days:15,
            level:"Level (2)",
            imgurl:"/assets/images/Homepage/mostpopularcourses/instructorlittlepic.png",
            instructorname:"Professor Mokhter Ahmad",
            price:1200,
            bigimgurl:"/assets/images/Homepage/mostpopularcourses/cardpic4.png",
            description:{
                title:"Description",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread."
            },
            curriculum:{
                title:"Curriculum",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran."
            },
            certification:{
                title:"Certification",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran. Every Muslim recognizes the importance of learning"
            },
            coursecontent:[

                {
                    heading:"Lesson 1 : Preview",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                    ]
                }
            ],
            instructors:{
                heading:"Course Instructor:",
                rating:4.7,
                ratingcount:30,
                teachername:"Professor Mokhter Ahmad",
                designation:"Tarbiyah Ins, Instructor",
                 ratingsection:{
                    heading:"Rating",
                    rating:4.7,
                    totalratednumber:4200,
                    fivestarpercent:60,
                    fourstarpercent:30,
                    threestarpercent:15,
                    twostarpercent:10,
                    onestarpercent:5,
                }
            },
            studentreview:{
                heading:"Student Review:",
                data:[
                    {
                        name:"Student Name",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 2",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 3",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 4",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                ]
            }
        },
    
    ]
    const featuredCoursesCardAPI = [
        {
            id:1,
            pricebox:{
                price:1300,
                instructor:"Mokhter Ahmad",
                duration:"08 hr 15 mins"                ,
                lectures:"29",
                level:"02",
                language:"English",
                certificate:"Yes"
            },
            head:"Quranic Arabic Blended Professional 1",
            lessons:5,rating:4.7,
            days:15,
            level:"Level (2)",
            imgurl:"/assets/images/Homepage/mostpopularcourses/instructorlittlepic.png",
            instructorname:"Professor Mokhter Ahmad",
            price:1200,
            bigimgurl:"/assets/images/Homepage/mostpopularcourses/cardpic3.png",
            description:{
                title:"Description",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread."
            },
            curriculum:{
                title:"Curriculum",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran."
            },
            certification:{
                title:"Certification",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran. Every Muslim recognizes the importance of learning"
            },
            coursecontent:[

                {
                    heading:"Lesson 1 : Preview",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab2"
                        },
                        {
                            name:"Subtab3"
                        },
                        {
                            name:"Subtab4"
                        },
                    ]
                },
                {
                    heading:"Lesson 2 : Step 2",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                    ]
                },
            ],
            instructors:{
                heading:"Course Instructor:",
                rating:4.7,
                ratingcount:30,
                teachername:"Professor Mokhter Ahmad",
                designation:"Tarbiyah Ins, Instructor",
                ratingsection:{
                    heading:"Rating",
                    rating:4.7,
                    totalratednumber:4200,
                    fivestarpercent:60,
                    fourstarpercent:30,
                    threestarpercent:15,
                    twostarpercent:10,
                    onestarpercent:5,
                }
            },
            studentreview:{
                heading:"Student Review:",
                data:[
                    {
                        name:"Student Name",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 2",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 3",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 4",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                ]
            }


        },
        {
            id:2,
            pricebox:{
                price:1300,
                instructor:"Mokhter Ahmad",
                duration:"08 hr 15 mins"                ,
                lectures:"29",
                level:"02",
                language:"English",
                certificate:"Yes"
            },
            head:"Quranic Arabic Grammar",
            lessons:5,rating:4.7,
            days:15,
            level:"All Levels",
            imgurl:"/assets/images/Homepage/mostpopularcourses/instructorlittlepic.png",
            instructorname:"Professor Mokhter Ahmad",
            price:1200,
            bigimgurl:"/assets/images/Homepage/mostpopularcourses/cardpic1.png",
            saletag:true,
            description:{
                title:"Description",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread."
            },
            curriculum:{
                title:"Curriculum",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran."
            },
            certification:{
                title:"Certification",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran. Every Muslim recognizes the importance of learning"
            },
            coursecontent:[

                {
                    heading:"Lesson 1 : Preview",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                    ]
                }
            ],
            instructors:{
                heading:"Course Instructor:",
                rating:4.7,
                ratingcount:30,
                teachername:"Professor Mokhter Ahmad",
                designation:"Tarbiyah Ins, Instructor",
                 ratingsection:{
                    heading:"Rating",
                    rating:4.7,
                    totalratednumber:4200,
                    fivestarpercent:60,
                    fourstarpercent:30,
                    threestarpercent:15,
                    twostarpercent:10,
                    onestarpercent:5,
                }
            },
            studentreview:{
                heading:"Student Review:",
                data:[
                    {
                        name:"Student Name",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 2",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 3",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 4",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                ]
            }
        },
        {
            id:3,
            pricebox:{
                price:1300,
                instructor:"Mokhter Ahmad",
                duration:"08 hr 15 mins"                ,
                lectures:"29",
                level:"02",
                language:"English",
                certificate:"Yes"
            },
            head:"Quranic arabic blended",
            lessons:5,rating:4.7,
            days:15,
            level:"Level (3)",
            imgurl:"/assets/images/Homepage/mostpopularcourses/instructorlittlepic.png",
            instructorname:"Professor Mokhter Ahmad",
            price:1200,
            bigimgurl:"/assets/images/Homepage/mostpopularcourses/cardpic2.png",
            description:{
                title:"Description",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread."
            },
            curriculum:{
                title:"Curriculum",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran."
            },
            certification:{
                title:"Certification",
                detail:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran. Every Muslim recognizes the importance of learning"
            },
            coursecontent:[

                {
                    heading:"Lesson 1 : Preview",
                    body:[
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                        {
                            name:"Subtab"
                        },
                    ]
                }
            ],
            instructors:{
                heading:"Course Instructor:",
                rating:4.7,
                ratingcount:30,
                teachername:"Professor Mokhter Ahmad",
                designation:"Tarbiyah Ins, Instructor",
                 ratingsection:{
                    heading:"Rating",
                    rating:4.7,
                    totalratednumber:4200,
                    fivestarpercent:60,
                    fourstarpercent:30,
                    threestarpercent:15,
                    twostarpercent:10,
                    onestarpercent:5,
                }
            },
            studentreview:{
                heading:"Student Review:",
                data:[
                    {
                        name:"Student Name",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 2",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 3",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                    {
                        name:"Student Name 4",
                        rating: 4.7,
                        designation:"Student Designation, Addess",
                        img:"/assets/images/CourseDetailsPage/studentpic.png",
                        paragraph:"Every Muslim recognizes the importance of learning the Quran, but only a few attempts to learn the Quran with understanding and spend their lives on the basis of the Quran and Islamic teachings. Tarbyiah Institute is established with the aim to spread the knowledge of the Quran, especially among those where there are no reliable resources for learning the Quran.  knowledge of the Quran"
                    },
                ]
            }
        },
 
    ]

const CoursesCardAPI = () => {
  return (
    <>

    </>
  )
}
export {courseCardAPI,popularCoursesCardAPI,featuredCoursesCardAPI}

export default CoursesCardAPI