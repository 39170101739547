import React from 'react'

const donationAPI = {
    galleryimg:[
        {
            img:"./assets/images/Donationpage/gallery/1.png"
        },
        {
            img:"./assets/images/Donationpage/gallery/2.png"
        },
        {
            img:"./assets/images/Donationpage/gallery/3.png"
        },
        {
            img:"./assets/images/Donationpage/gallery/4.png"
        },
        {
            img:"./assets/images/Donationpage/gallery/5.png"
        },
        {
            img:"./assets/images/Donationpage/gallery/6.png"
        },
        {
            img:"./assets/images/Donationpage/gallery/7.png"
        },
        {
            img:"./assets/images/Donationpage/gallery/8.png"
        },
        {
            img:"./assets/images/Donationpage/gallery/9.png"
        },
    ]
}

const DonationPageAPI = () => {
  return (
    <>
    </>
  )
}

export {donationAPI}

export default DonationPageAPI